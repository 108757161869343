import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import poweredByStrava from "../assets/powered_by_strava.png";
import html2canvas from "html2canvas";

function ActivityRoast() {
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState("");
  const [roastResult, setRoastResult] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const [roastIntensity, setRoastIntensity] = useState(5);
  const [unitPreference, setUnitPreference] = useState("kilometers");

  const [loadingEmoji, setLoadingEmoji] = useState("🏃‍♂️");
  const athleticEmojis = [
    "🏃‍♂️",
    "🚴‍♀️",
    "🏊‍♂️",
    "🏃🏻‍♀️",
    "🏋️‍♀️",
    "🤸‍♀️",
    "🏄‍♂️",
    "🏃🏿‍♂️",
    "🚣‍♀️",
    "🧘‍♀️",
    "🤾‍♂️",
    "🚴🏾‍♀️",
    "🤼‍♀️",
    "🏇",
    "🤺",
    "🏌️‍♂️",
    "⛹️‍♂️",
    "🏂",
    "🧗‍♀️",
    "🤽‍♂️",
    "🚵‍♀️",
  ];

  const loadingIntervalRef = useRef(null);

  const handleIntensityChange = (event) => {
    setRoastIntensity(parseInt(event.target.value, 10));
  };

  const fetchUserProfile = useCallback(async () => {
    try {
      const token = localStorage.getItem("strava_token");
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/user_profile",
        {
          params: { token },
          withCredentials: true,
        }
      );
      const userProfileData = response.data;

      if (userProfileData.profile) {
        userProfileData.profile = `https://roast-my-strava-backend-production.up.railway.app/proxy-image?url=${encodeURIComponent(
          userProfileData.profile
        )}`;
      }

      setUserProfile(userProfileData);
      setUnitPreference(
        userProfileData.country === "United States" ? "miles" : "kilometers"
      );
      return userProfileData;
    } catch (error) {
      console.error("Error fetching user profile:", error);
      navigate("/");
    }
  }, [navigate]);

  const checkLoginStatus = useCallback(async () => {
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        navigate("/");
        return;
      }
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/check_auth",
        {
          params: { token },
          withCredentials: true,
        }
      );
      if (!response.data.authenticated) {
        localStorage.removeItem("strava_token");
        navigate("/");
      } else {
        await fetchUserProfile();
        await fetchActivities();
      }
    } catch (error) {
      localStorage.removeItem("strava_token");
      navigate("/");
    }
  }, [navigate, fetchUserProfile]);

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  const fetchActivities = async () => {
    const token = localStorage.getItem("strava_token");
    try {
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/recent_activities",
        {
          params: { token },
          withCredentials: true,
        }
      );
      setActivities(response.data);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  const handleActivityChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  const handleRoast = async () => {
    if (!selectedActivity) return;

    setLoading(true);
    startLoadingAnimation();
    try {
      const token = localStorage.getItem("strava_token");
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/roast_activity",
        {
          params: {
            token,
            id: selectedActivity,
            intensity: roastIntensity,
            unitPreference,
          },
          withCredentials: true,
        }
      );
      setRoastResult(response.data.message);
    } catch (error) {
      console.error("Error roasting activity:", error);
      setRoastResult("Oops! Looks like we hit a wall. Please try again later.");
    } finally {
      setLoading(false);
      stopLoadingAnimation();
    }
  };

  const startLoadingAnimation = () => {
    let index = 0;
    loadingIntervalRef.current = setInterval(() => {
      setLoadingEmoji(athleticEmojis[index]);
      index = (index + 1) % athleticEmojis.length;
    }, 200);
  };

  const stopLoadingAnimation = () => {
    if (loadingIntervalRef.current) {
      clearInterval(loadingIntervalRef.current);
      loadingIntervalRef.current = null;
      setLoadingEmoji("🏃‍♂️");
    }
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(
        `I just got my Strava activity roasted by an AI...\n\n${roastResult}\n\nGet your Strava roasted at https://roastmystrava.com`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleSaveRoast = () => {
    const roastElement = document.querySelector(".roast-result-container");
    if (roastElement) {
      html2canvas(roastElement).then((canvas) => {
        const link = document.createElement("a");
        link.download = `roast_${formatDate(new Date())}.png`;
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  };

  const formatDate = (date) => {
    return date.toISOString().replace(/[-:]/g, "").split(".")[0];
  };

  const handleLogout = () => {
    localStorage.removeItem("strava_token");
    navigate("/");
  };

  useEffect(() => {
    return () => {
      if (loadingIntervalRef.current) {
        clearInterval(loadingIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className="Dashboard">
      <Helmet>
        <title>Roast My Strava | Activity Roast</title>
        <meta
          name="description"
          content="Analyze and roast a specific Strava activity with AI-generated commentary. A fun way to celebrate your fitness achievements!"
        />
        <meta
          name="keywords"
          content="Strava, AI, roast my strava, roastmystrava, Strava AI, running, cycling, humor, fitness, activity analysis"
        />
      </Helmet>
      <h1 className="roast-header">Roast My Strava Activity (beta)</h1>
      <div className="user-info">
        <div className="profile-info">
          {userProfile ? (
            <>
              <img
                src={userProfile.profile}
                alt="Profile"
                className="profile-image"
              />
              <div className="user-details">
                <h1>
                  {userProfile.firstname} {userProfile.lastname}
                </h1>
                <span className="user-location">
                  {[userProfile.city, userProfile.state, userProfile.country]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>
            </>
          ) : (
            <div className="loading-message">
              <p>Connecting to Strava...</p>
            </div>
          )}
        </div>
      </div>
      {userProfile && (
        <div className="roast-container">
          <select
            onChange={handleActivityChange}
            value={selectedActivity}
            className="activity-select"
          >
            <option value="">Select an activity</option>
            {activities.map((activity) => (
              <option key={activity.id} value={activity.id}>
                {activity.name} -{" "}
                {new Date(activity.start_date).toLocaleDateString()}
              </option>
            ))}
          </select>
          <div className="unit-preference-switcher">
            <button
              className={`switcher-button ${
                unitPreference === "miles" ? "active" : ""
              }`}
              onClick={() => setUnitPreference("miles")}
            >
              <span role="img" aria-label="US Flag">
                🇺🇸
              </span>
              Miles
            </button>
            <button
              className={`switcher-button ${
                unitPreference === "kilometers" ? "active" : ""
              }`}
              onClick={() => setUnitPreference("kilometers")}
            >
              <span role="img" aria-label="World">
                🌍
              </span>
              Kilometers
            </button>
          </div>
          <div className="intensity-slider-container">
            <div className="intensity-slider">
              <span role="img" aria-label="Tame">
                🧊
              </span>
              <input
                id="intensity-slider"
                type="range"
                min="0"
                max="10"
                value={roastIntensity}
                onChange={handleIntensityChange}
                className="slider"
              />
              <span role="img" aria-label="Intense">
                🌶️
              </span>
            </div>
          </div>
          <button
            onClick={handleRoast}
            disabled={loading || !selectedActivity}
            className="roast-button"
          >
            {loading
              ? `Generating Roast... ${loadingEmoji}`
              : "Roast This Activity 🔥"}
          </button>
          {roastResult && (
            <>
              <div className="roast-result-container">
                <div className="roast-result-text">{roastResult}</div>
                <div className="roast-result-footer">
                  <div className="user-profile-mini">
                    <img
                      src={userProfile.profile}
                      alt={`${userProfile.firstname}'s profile`}
                      className="mini-profile-image"
                      crossOrigin="anonymous"
                    />
                    <div className="user-info-mini">
                      <span className="user-name-mini">
                        {[userProfile.firstname, userProfile.lastname]
                          .filter(Boolean)
                          .join(" ")}
                      </span>
                      <span className="user-location-mini">
                        {[userProfile.city, userProfile.state]
                          .filter(Boolean)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                  <div className="roast-source-container">
                    <div className="roast-source">RoastMyStrava.com</div>
                    <img
                      src={poweredByStrava}
                      alt="Powered by Strava"
                      className="powered-by-strava"
                    />
                  </div>
                </div>
              </div>
              <div className="share-buttons">
                <button onClick={handleShare}>
                  {copied ? "Copied! 📋" : "Copy Roast 📝"}
                </button>
                <button onClick={handleSaveRoast}>Save Roast 💾</button>
              </div>
            </>
          )}
        </div>
      )}
      <div className="logout-container">
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
      <img
        src={poweredByStrava}
        alt="Powered by Strava"
        style={{
          width: "120px",
          display: "block",
          margin: "12px auto",
        }}
      />
    </div>
  );
}

export default ActivityRoast;
