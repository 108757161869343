import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import poweredByStrava from "../assets/powered_by_strava.png";
import { Helmet } from "react-helmet";
import Select from "react-select";

function TitleGenerator() {
  const [generatedTitle, setGeneratedTitle] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [titleStyle, setTitleStyle] = useState("creative");
  const [titleRandomness, setTitleRandomness] = useState(5);
  const [keywords, setKeywords] = useState("");

  const [loadingEmoji, setLoadingEmoji] = useState("🏃‍♂️");
  const athleticEmojis = [
    "🏃‍♂️",
    "🚴‍♀️",
    "🏊‍♂️",
    "🏃🏻‍♀️",
    "🏋️‍♀️",
    "🤸‍♀️",
    "🏄‍♂️",
    "🏃🏿‍♂️",
    "🚣‍♀️",
    "🧘‍♀️",
    "🤾‍♂️",
    "🚴🏾‍♀️",
    "🤼‍♀️",
    "🏇",
    "🤺",
    "🏌️‍♂️",
    "⛹️‍♂️",
    "🏂",
    "🧗‍♀️",
    "🤽‍♂️",
    "🚵‍♀️",
  ];

  const loadingIntervalRef = useRef(null);

  const [unitPreference, setUnitPreference] = useState("kilometers");

  const [copySuccess, setCopySuccess] = useState(false);

  const handleRandomnessChange = (event) => {
    setTitleRandomness(parseInt(event.target.value, 10));
  };

  const fetchUserProfile = useCallback(async () => {
    try {
      const token = localStorage.getItem("strava_token");
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/user_profile",
        {
          params: { token },
          withCredentials: true,
        }
      );
      const userProfileData = response.data;

      // Proxy the profile image through the backend
      if (userProfileData.profile) {
        userProfileData.profile = `https://roast-my-strava-backend-production.up.railway.app/proxy-image?url=${encodeURIComponent(
          userProfileData.profile
        )}`;
      }

      setUserProfile(userProfileData);
      setUnitPreference(
        userProfileData.country === "United States" ? "miles" : "kilometers"
      );
      return userProfileData;
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 ||
          error.response.status === 429 ||
          error.response.status === 403)
      ) {
        localStorage.removeItem("strava_token");
        navigate("/");
      } else {
        console.error("Error fetching user profile:", error);
      }
    }
  }, [navigate]);

  const checkLoginStatus = useCallback(async () => {
    console.log("Checking login status");
    try {
      const token = localStorage.getItem("strava_token");
      //   console.log("Retrieved token:", token);
      if (!token) {
        console.log("No token found, redirecting to home");
        navigate("/");
        return;
      }
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/check_auth",
        {
          params: { token },
          withCredentials: true,
        }
      );
      if (!response.data.authenticated) {
        console.log("Not authenticated, clearing token and redirecting");
        localStorage.removeItem("strava_token");
        navigate("/");
      } else {
        await fetchUserProfile();
      }
    } catch (error) {
      console.error("Error checking login status:", error);
      localStorage.removeItem("strava_token");
      navigate("/");
    }
  }, [navigate, fetchUserProfile]);

  const fetchRecentActivities = useCallback(async () => {
    try {
      const token = localStorage.getItem("strava_token");
      //   console.log("Token for fetching activities:", token); // Add this line for debugging
      if (!token) {
        console.log("No token found, redirecting to home");
        navigate("/");
        return;
      }
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/get_activities",
        {
          params: { token },
          withCredentials: true,
        }
      );
      setActivities(response.data);
    } catch (error) {
      console.error("Error fetching recent activities:", error);
      if (error.response && error.response.status === 401) {
        console.log("Unauthorized, clearing token and redirecting");
        localStorage.removeItem("strava_token");
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const initializeComponent = async () => {
      try {
        await checkLoginStatus();
        await fetchRecentActivities();
      } catch (error) {
        // console.error("Error initializing component:", error);
        // Handle initialization error (e.g., show an error message to the user)
      }
    };
    initializeComponent();
  }, [checkLoginStatus, fetchRecentActivities]);

  const activityOptions = activities.map((activity) => {
    const date = activity.start_date
      ? new Date(activity.start_date).toLocaleDateString()
      : "";
    const type = activity.type || "";
    const name = activity.name || "";

    const label = [date, type, name].filter(Boolean).join(" | ");

    return {
      value: activity.id,
      label: label,
    };
  });

  const handleActivityChange = (selectedOption) => {
    const activity = activities.find((a) => a.id === selectedOption.value);
    setSelectedActivity(activity);
    setGeneratedTitle(""); // Clear the generated title when a new activity is selected
  };

  const handleGenerateTitle = async () => {
    if (!selectedActivity) {
      alert("Please select an activity to generate a title for.");
      return;
    }

    setLoading(true);
    startLoadingAnimation();
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        throw new Error("No token found");
      }
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/generate_title",
        {
          params: {
            token,
            randomness: titleRandomness,
            style: titleStyle,
            keywords,
            activity_id: selectedActivity.id,
            unitPreference,
          },
          withCredentials: true,
        }
      );
      setGeneratedTitle(response.data.title);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        navigate("/", { state: { error: "rate_limit" } });
      } else if (
        error.response &&
        error.response.data.error === "Error communicating with Strava API"
      ) {
        navigate("/", { state: { error: "strava_api" } });
      } else {
        setGeneratedTitle(
          "Oops! Looks like we hit a wall. Please try again later."
        );
      }
    } finally {
      setLoading(false);
      stopLoadingAnimation();
    }
  };

  const startLoadingAnimation = () => {
    let index = 0;
    loadingIntervalRef.current = setInterval(() => {
      setLoadingEmoji(athleticEmojis[index]);
      index = (index + 1) % athleticEmojis.length;
    }, 200);
  };

  const stopLoadingAnimation = () => {
    if (loadingIntervalRef.current) {
      clearInterval(loadingIntervalRef.current);
      loadingIntervalRef.current = null;
      setLoadingEmoji("🏃‍♂️"); // Reset to initial emoji if desired
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("strava_token");
    navigate("/");
  };

  const handleCopyTitle = () => {
    navigator.clipboard.writeText(generatedTitle).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  // Cleanup interval on component unmount
  useEffect(() => {
    return () => {
      if (loadingIntervalRef.current) {
        clearInterval(loadingIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className="Dashboard">
      <Helmet>
        <title>Strava Title Generator | Roast My Strava</title>
        <meta
          name="description"
          content="Connect your Strava account and get AI-generated titles for your activities. A fun way to name your running, cycling, and other activities!"
        />
        <meta
          name="keywords"
          content="Strava, AI, title generator, strava titles, activity names, running, cycling, fitness"
        />
      </Helmet>
      <h1 className="roast-header" onClick={() => navigate("/dashboard")}>
        Strava Title Generator (beta) 🧪
      </h1>
      <div className="user-info">
        <div className="profile-info">
          {userProfile ? (
            <>
              <img
                src={userProfile.profile}
                alt="Profile"
                className="profile-image"
              />
              <div className="user-details">
                <h1>
                  {userProfile.firstname} {userProfile.lastname}
                </h1>
                <span className="user-location">
                  {[userProfile.city, userProfile.state, userProfile.country]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>
            </>
          ) : (
            <div className="loading-message">
              <p>Connecting to Strava...</p>
            </div>
          )}
        </div>
      </div>
      {userProfile && (
        <div className="title-generator-container">
          <div className="activity-dropdown">
            <Select
              options={activityOptions}
              onChange={handleActivityChange}
              value={
                selectedActivity
                  ? {
                      value: selectedActivity.id,
                      label: `${selectedActivity.name} - ${new Date(
                        selectedActivity.start_date
                      ).toLocaleDateString()}`,
                    }
                  : null
              }
              placeholder="Select an activity"
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
              backgroundColor: "#f0f0f0",
              borderRadius: "20px",
              padding: "3px",
              width: "280px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <button
              style={{
                flex: 1,
                padding: "8px 12px",
                border: "none",
                backgroundColor:
                  unitPreference === "miles" ? "#FC4C02" : "transparent",
                color: unitPreference === "miles" ? "white" : "#333",
                cursor: "pointer",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
              onClick={() => setUnitPreference("miles")}
            >
              <span
                role="img"
                aria-label="US Flag"
                style={{ fontSize: "1.2em" }}
              >
                🇺🇸
              </span>
              Miles
            </button>
            <button
              style={{
                flex: 1,
                padding: "8px 12px",
                border: "none",
                backgroundColor:
                  unitPreference === "kilometers" ? "#FC4C02" : "transparent",
                color: unitPreference === "kilometers" ? "white" : "#333",
                cursor: "pointer",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
              onClick={() => setUnitPreference("kilometers")}
            >
              <span role="img" aria-label="World" style={{ fontSize: "1.2em" }}>
                🌍
              </span>
              Kilometers
            </button>
          </div>
          <div className="style-buttons">
            {["Creative", "Funny", "Random"].map((style) => (
              <button
                key={style}
                onClick={() => setTitleStyle(style.toLowerCase())}
                style={{
                  backgroundColor:
                    titleStyle === style.toLowerCase()
                      ? "#FC4C02"
                      : "transparent",
                  color: titleStyle === style.toLowerCase() ? "white" : "#333",
                }}
              >
                {style}
              </button>
            ))}
          </div>
          <div className="randomness-slider">
            <div className="randomness-slider">
              <span role="img" aria-label="Normal">
                🧊
              </span>
              <input
                id="randomness-slider"
                type="range"
                min="0"
                max="10"
                value={titleRandomness}
                onChange={handleRandomnessChange}
                className="slider"
              />
              <span role="img" aria-label="Random">
                🎲
              </span>
            </div>
          </div>
          <div className="keywords-input">
            <input
              type="text"
              placeholder="Enter keywords or details (optional)"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
            />
          </div>
          <button
            onClick={handleGenerateTitle}
            disabled={loading}
            className="generate-button"
          >
            {loading
              ? `Generating Title... ${loadingEmoji}`
              : "Generate Title 🏆"}
          </button>
          {generatedTitle && (
            <div className="generated-title-container">
              <div className="generated-title">{generatedTitle}</div>
              <button onClick={handleCopyTitle} className="copy-button">
                {copySuccess ? "Copied!" : "Copy Title"}
              </button>
            </div>
          )}
        </div>
      )}
      {userProfile && (
        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
      )}
      <img
        src={poweredByStrava}
        alt="Powered by Strava"
        style={{
          width: "120px",
          display: "block",
          margin: "12px auto",
        }}
      />
    </div>
  );
}

export default TitleGenerator;
