import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import ActivityRoast from "./components/ActivityRoast";
import AnalyzeActivity from "./components/AnalyzeActivity";
import TitleGenerator from "./components/TitleGenerator";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/activity-roast" element={<ActivityRoast />} />
        <Route path="/activity" element={<AnalyzeActivity />} />
        <Route path="/strava-title-generator" element={<TitleGenerator />} />
        <Route
          path="/about"
          element={
            <Navigate to="https://jasonkuperberg.com/roast-my-strava" replace />
          }
        />
        {/* any other link should redirect to home */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
