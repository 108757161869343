import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import poweredByStrava from "../assets/powered_by_strava.png";
import { Helmet } from "react-helmet";
import html2canvas from "html2canvas";

function Dashboard() {
  const [roastResult, setRoastResult] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  // const [selectedModel, setSelectedModel] = useState("default");

  const [roastIntensity, setRoastIntensity] = useState(5);
  const [unitPreference, setUnitPreference] = useState("kilometers");

  const [loadingEmoji, setLoadingEmoji] = useState("🏃‍♂️");
  const athleticEmojis = [
    "🏃‍♂️",
    "🚴‍♀️",
    "🏊‍♂️",
    "🏃🏻‍♀️",
    "🏋️‍♀️",
    "🤸‍♀️",
    "🏄‍♂️",
    "🏃🏿‍♂️",
    "🚣‍♀️",
    "🧘‍♀️",
    "🤾‍♂️",
    "🚴🏾‍♀️",
    "🤼‍♀️",
    "🏇",
    "🤺",
    "🏌️‍♂️",
    "⛹️‍♂️",
    "🏂",
    "🧗‍♀️",
    "🤽‍♂️",
    "🚵‍♀️",
  ];

  const loadingIntervalRef = useRef(null);

  const [isExtraMeanMode, setIsExtraMeanMode] = useState(false);
  // const [isYearRoastMode, setIsYearRoastMode] = useState(false);
  // const currentYear = new Date().getFullYear();
  const USE_LOCAL_API = "false";
  // const USE_LOCAL_API = "true";

  const handleIntensityChange = (event) => {
    setRoastIntensity(parseInt(event.target.value, 10));
  };

  const fetchUserProfileAndCheckAuth = useCallback(async () => {
    // console.log("Checking login status and fetching user profile");
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        console.log("No token found, redirecting to home");
        navigate("/");
        return;
      }

      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/user_profile",
        {
          params: { token },
          withCredentials: true,
        }
      );

      const userProfileData = response.data;
      // console.log(userProfileData);

      // Proxy the profile image through the backend
      if (userProfileData.profile) {
        userProfileData.profile = `https://roast-my-strava-backend-production.up.railway.app/proxy-image?url=${encodeURIComponent(
          userProfileData.profile
        )}`;
      }

      setUserProfile(userProfileData);
      setUnitPreference(
        userProfileData.country === "United States" ? "miles" : "kilometers"
      );
    } catch (error) {
      console.error("Error fetching user profile:", error);
      if (
        error.response &&
        (error.response.status === 401 ||
          error.response.status === 429 ||
          error.response.status === 403)
      ) {
        console.log("Not authenticated, clearing token and redirecting");
        localStorage.removeItem("strava_token");
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserProfileAndCheckAuth();
  }, [fetchUserProfileAndCheckAuth]);

  const handleRoast = async () => {
    setLoading(true);
    startLoadingAnimation();
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        throw new Error("No token found");
      }
      const response = await axios.get(
        USE_LOCAL_API === "true"
          ? "http://127.0.0.1:5000/roast"
          : "https://roast-my-strava-backend-production.up.railway.app/roast",
        {
          params: {
            token,
            intensity: isExtraMeanMode ? 10 : roastIntensity,
            unitPreference,
            model: isExtraMeanMode ? "x" : "default",
            // yearMode: isYearRoastMode ? currentYear : null,
          },
          withCredentials: true,
        }
      );
      setRoastResult(response.data.message);
    } catch (error) {
      // console.error("Error:", error);
      if (error.response && error.response.status === 429) {
        // Rate limit error
        navigate("/", { state: { error: "rate_limit" } });
      } else if (
        error.response &&
        error.response.data.error === "Error communicating with Strava API"
      ) {
        navigate("/", { state: { error: "strava_api" } });
      } else {
        setRoastResult(
          "Oops! Looks like we hit a wall. Please try again later."
        );
      }
    } finally {
      setLoading(false);
      stopLoadingAnimation();
    }
  };

  const startLoadingAnimation = () => {
    let index = 0;
    loadingIntervalRef.current = setInterval(() => {
      setLoadingEmoji(athleticEmojis[index]);
      index = (index + 1) % athleticEmojis.length;
    }, 200);
  };

  const stopLoadingAnimation = () => {
    if (loadingIntervalRef.current) {
      clearInterval(loadingIntervalRef.current);
      loadingIntervalRef.current = null;
      setLoadingEmoji("🏃‍♂️"); // Reset to initial emoji if desired
    }
  };
  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(
        `I just got my Strava roasted by an AI...\n\n${roastResult}\n\nGet your Strava roasted at https://roastmystrava.com`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      // console.error("Failed to copy: ", err);
    }
  };

  const handleSaveRoast = () => {
    const roastContainer = document.querySelector(".roast-result-container");
    if (roastContainer) {
      const scale = 10; // High quality scale
      const padding = 40 * scale;

      html2canvas(roastContainer, {
        useCORS: true,
        allowTaint: false,
        scale: scale,
        logging: false,
        backgroundColor: null, // Transparent background
      }).then((canvas) => {
        // Create a new canvas with padding
        const newCanvas = document.createElement("canvas");
        const context = newCanvas.getContext("2d");
        newCanvas.width = canvas.width + padding * 2;
        newCanvas.height = canvas.height + padding * 2;

        // Fill the entire canvas with Strava orange background
        context.fillStyle = "#FC4C02";
        context.fillRect(0, 0, newCanvas.width, newCanvas.height);

        // Draw the original canvas onto the new canvas with padding
        context.drawImage(canvas, padding, padding);

        // Create download link
        const link = document.createElement("a");
        link.download = `roastmystrava_${
          userProfile.firstname?.toLowerCase() || ""
        }${userProfile.firstname && userProfile.lastname ? "_" : ""}${
          userProfile.lastname?.toLowerCase() || ""
        }_${formatDate(new Date())}.png`;
        link.href = newCanvas.toDataURL("image/png");
        link.click();
      });
    }
  };

  const formatDate = (date) => {
    return date.toISOString().replace(/[-:]/g, "").split(".")[0];
  };

  const handleLogout = () => {
    localStorage.removeItem("strava_token");
    navigate("/");
  };

  // Cleanup interval on component unmount
  useEffect(() => {
    return () => {
      if (loadingIntervalRef.current) {
        clearInterval(loadingIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className="Dashboard">
      <Helmet>
        <title>Roast My Strava | Dashboard</title>
        <meta
          name="description"
          content="Connect your Strava account and get witty, AI-generated commentary on your Strava profile and activities. A fun way to celebrate your running, cycling, and other activities!"
        />
        <meta
          name="keywords"
          content="Strava, AI, roast my strava, roastmystrava, Strava AI,running, cycling, humor, fitness"
        />
      </Helmet>
      <h1 className="roast-header">Roast My Strava</h1>
      <div className="user-info">
        <div className="profile-info">
          {userProfile ? (
            <>
              <img
                src={userProfile.profile}
                alt="Profile"
                className="profile-image"
              />
              <div className="user-details">
                <h1>
                  {userProfile.firstname} {userProfile.lastname}
                </h1>
                <span className="user-location">
                  {[userProfile.city, userProfile.state, userProfile.country]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>
            </>
          ) : (
            <div className="loading-message">
              <p>Connecting to Strava...</p>
            </div>
          )}
        </div>
      </div>
      {userProfile && (
        <div className="roast-container">
          {(userProfile?.id === 1 || userProfile?.id === 2) && (
            <div
              className="mean-mode-container"
              style={{ marginBottom: "1.5rem", textAlign: "center" }}
            >
              <h3
                style={{
                  color: "#666",
                  marginTop: "0",
                  marginBottom: "0.5rem",
                }}
              >
                Roast My Strava is not mean enough?
              </h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "0.5rem",
                  flexDirection: "column",
                }}
              >
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={isExtraMeanMode}
                    onChange={(e) => {
                      setIsExtraMeanMode(e.target.checked);
                      setRoastResult(""); // Clear roast results when mode is toggled
                    }}
                  />
                  <span className="toggle-slider"></span>
                </label>
                {isExtraMeanMode && (
                  <span style={{ color: "red" }}>
                    ⚠️ Extra Mean Mode Activated ⚠️
                  </span>
                )}
              </div>
              {isExtraMeanMode && (
                <p
                  style={{
                    fontSize: "0.8rem",
                    color: "#666",
                    maxWidth: "80%",
                    margin: "0 auto",
                    fontStyle: "italic",
                  }}
                >
                  Warning: We cannot be held responsible for potentially
                  offensive content generated in this mode.
                </p>
              )}
            </div>
          )}

          {/* Year Roast Mode Container - Commented out
          <div
            className="year-roast-container"
            style={{ marginBottom: "1.5rem", textAlign: "center" }}
          >
            <h3
              style={{
                color: "#666",
                marginTop: "0",
                marginBottom: "0.5rem",
              }}
            >
              {currentYear} Strava Roasted
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                marginBottom: "0.5rem",
                flexDirection: "column",
              }}
            >
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={isYearRoastMode}
                  onChange={(e) => {
                    setIsYearRoastMode(e.target.checked);
                    setRoastResult("");
                  }}
                />
                <span className="toggle-slider"></span>
              </label>
              {isYearRoastMode && (
                <span style={{ color: "#FC4C02" }}>
                  🎯 Year Review Roast Mode Activated
                </span>
              )}
            </div>
          </div>
          */}

          <div
            className="intensity-slider-container"
            style={{
              opacity: isExtraMeanMode ? 0.5 : 1,
              pointerEvents: isExtraMeanMode ? "none" : "auto",
            }}
          >
            <div className="intensity-slider">
              <span role="img" aria-label="Tame">
                🧊
              </span>
              <input
                id="intensity-slider"
                type="range"
                min="0"
                max="10"
                value={isExtraMeanMode ? 10 : roastIntensity}
                onChange={handleIntensityChange}
                className="slider"
                disabled={isExtraMeanMode}
              />
              <span role="img" aria-label="Intense">
                🌶️
              </span>
            </div>
          </div>
          <button
            onClick={handleRoast}
            disabled={loading}
            className="roast-button"
          >
            {loading
              ? `Generating Roast... ${loadingEmoji}`
              : "Roast My Strava 🔥"}
          </button>
          {roastResult && (
            <>
              <div className="roast-result-container">
                {/* Year roast header - Commented out
                {isYearRoastMode && (
                  <div
                    className="year-roast-header"
                    style={{
                      fontSize: "1.5em",
                      fontWeight: "bold",
                      marginBottom: "0.6rem",
                      color: "#FC4C02",
                      textAlign: "center",
                    }}
                  >
                    {currentYear} Strava Roasted
                  </div>
                )}
                */}
                <div className="roast-result-text">{roastResult}</div>
                <div className="roast-result-footer">
                  <div className="user-profile-mini">
                    <img
                      src={userProfile.profile}
                      alt={`${userProfile.firstname}'s profile`}
                      className="mini-profile-image"
                      crossOrigin="anonymous" // Added crossOrigin attribute
                    />
                    <div className="user-info-mini">
                      <span className="user-name-mini">
                        {[userProfile.firstname, userProfile.lastname]
                          .filter(Boolean)
                          .join(" ")}
                      </span>
                      <span className="user-location-mini">
                        {[userProfile.city, userProfile.state]
                          .filter(Boolean)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                  <div className="roast-source-container">
                    <div
                      className="roast-source"
                      style={
                        isExtraMeanMode && window.innerWidth > 768
                          ? { fontSize: "1.2em" }
                          : {}
                      }
                    >
                      RoastMyStrava.com
                    </div>
                    {!isExtraMeanMode && (
                      <img
                        src={poweredByStrava}
                        alt="Powered by Strava"
                        className="powered-by-strava"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="share-buttons">
                <button onClick={handleShare}>
                  {copied ? "Copied! 📋" : "Copy Roast 📝"}
                </button>
                <button onClick={handleSaveRoast}>Save Roast 💾</button>
              </div>
            </>
          )}
        </div>
      )}
      {userProfile && (
        <div className="bottom-link-container">
          <Link
            to="/activity"
            className="bottom-link"
            style={{ fontSize: "16px" }}
          >
            New: Activity Roast
          </Link>
        </div>
      )}
      {userProfile && (
        <div className="bottom-link-container">
          <Link to="/strava-title-generator" className="bottom-link">
            Strava Title Generator (beta) 🧪
          </Link>
        </div>
      )}
      {/* <div className="bottom-link-container">
        <a
          href="https://marathons2025.com?utm_source=roastmystrava"
          className="bottom-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Marathon Search and AI Training Plan Generator
        </a>
      </div> */}
      {userProfile && (
        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
      )}
      {!isExtraMeanMode && (
        <img
          src={poweredByStrava}
          alt="Powered by Strava"
          style={{
            width: "120px",
            display: "block",
            margin: "12px auto",
          }}
        />
      )}
    </div>
  );
}

export default Dashboard;
