import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import axios from "axios";
import poweredByStrava from "../assets/powered_by_strava.png";
import sabrina_juno from "../assets/sabrina_juno.jpeg";
import eminem_lose_yourself from "../assets/eminem_lose_yourself.jpeg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 0rem 2rem;
  background-color: #f7f7f7;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 3.6rem;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 2rem 1rem 1rem;
`;

const RightColumn = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 2rem 0rem;

  @media (min-width: 1024px) {
    margin-top: 0;
    position: sticky;
    top: 2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.2rem;
  color: #fc4c02;
  margin-bottom: 0.6rem;
  text-align: center;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  line-height: 2.4rem;
  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #666;
  margin-bottom: 1.2rem;
  text-align: center;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  line-height: 1.4;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`;

const InputGroup = styled.div`
  margin: 1rem 0 0;
  width: 100%;
  max-width: 500px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const Input = styled.input`
  width: calc(100% - 2.2rem);
  padding: 0.6rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const ShareCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 1rem;
  background: white;
  border-radius: 16px;
  border: 6px solid #fc4c02;
  box-shadow: 0 4px 12px rgba(252, 76, 2, 0.1);
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const SongHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  margin: 1.8rem 0 0;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const AlbumCover = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const SongInfo = styled.div`
  flex: 1;
`;

const SongName = styled.h3`
  margin: 0;
  color: #333;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.3;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  font-weight: 600;
`;

const ArtistName = styled.p`
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const PlayCount = styled.div`
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #fc4c02;
  font-weight: bold;
  text-align: center;
  margin: 1.2rem 0 1rem;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  @media (max-width: 1024px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.4rem;
  background: #fff5f2;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  margin: 1rem 0;
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    gap: 1.8rem;
  }
  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const ShareCardTimeDisplay = styled(TimeDisplay)`
  margin: 1rem 0.2rem 1.4rem;
`;

const TimeUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const TimeValue = styled.span`
  font-size: 2.8rem;
  font-weight: bold;
  color: #fc4c02;
  line-height: 1;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;

const TimeLabel = styled.span`
  font-size: 1rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-top: 0.5rem;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }
`;

const PlayDetails = styled.div`
  color: #666;
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.6;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  font-weight: 500;
`;

const ShareFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.4rem;
  padding-top: 0.6rem;
  border-top: 1px solid #eee;
  font-size: 0.9rem;
  color: #666;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  width: 100%;
`;

const StatsDisplay = styled.div`
  margin: 1rem 0 0;
  padding: 1rem 1rem 0;
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const TimeToggle = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 500px;
`;

const TimeToggleButton = styled.button`
  background: ${(props) => (props.active ? "#fc4c02" : "white")};
  color: ${(props) => (props.active ? "white" : "#fc4c02")};
  border: 2px solid #fc4c02;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: ${(props) => (props.active ? "#e34400" : "#fff5f2")};
  }
`;

const ActivityToggle = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
  width: 100%;
  max-width: 500px;
`;

const SearchContainer = styled.div`
  position: relative;
  width: calc(100% - 1.8rem);
  max-width: 500px;
`;

const SearchDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;

  /* Customize scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #fc4c02;
    border-radius: 8px;
  }
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  flex-direction: row;
  gap: 0.6rem;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #fff5f2;
  }
`;

const StatsTitle = styled.h3`
  color: #333;
  font-size: 1.2rem;
  margin: 0 0 1rem;
  font-weight: 600;
  line-height: 1.4;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const StatsTime = styled.p`
  margin: 0;
  font-size: 1.1rem;
  color: #666;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  font-weight: 500;
`;

const LargeAlbumCover = styled(AlbumCover)`
  width: 100px;
  height: 100px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const LargeSongName = styled(SongName)`
  font-size: 1.4rem;
  margin-bottom: 0.3rem;
`;

const LargeArtistName = styled(ArtistName)`
  font-size: 1.1rem;
`;

const ActivityText = styled.div`
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  text-align: center;
  color: #666;
  font-size: 1.1rem;
`;

const SuggestedSongs = styled.div`
  margin-top: 0.8rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
`;

const SuggestedSong = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem;
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  gap: 0.4rem;
  width: fit-content;

  &:hover {
    background: #fff5f2;
    border-color: #fc4c02;
  }
`;

const SuggestedSongImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

const SuggestedSongInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  flex-wrap: wrap;
  align-items: baseline;
`;

const SuggestedSongName = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #333;
`;

const SuggestedSongArtist = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
  color: #666;
`;

const formatNumber = (num) => {
  return num.toLocaleString();
};

// Add new styled component for loading state
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  color: #fc4c02;
  font-size: 1.2rem;
`;

function Song() {
  const [query, setQuery] = useState("");
  const [songs, setSongs] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState("run");
  const [timeFrame, setTimeFrame] = useState("ytd");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [animatedTime, setAnimatedTime] = useState(0);
  const rightColumnRef = useRef(null);
  const [statsLoading, setStatsLoading] = useState(true);
  const [closeTimeout, setCloseTimeout] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  // const handleLogout = () => {
  //   localStorage.removeItem("strava_token");
  //   navigate("/");
  // };

  const suggestedSongs = [
    {
      id: "1",
      name: "Juno",
      artists: ["Sabrina Carpenter"],
      album_cover: sabrina_juno,
      duration_ms: 223192,
      external_urls: {
        spotify: "https://open.spotify.com/track/21B4gaTWnTkuSh77iWEXdS",
      },
    },
    {
      id: "2",
      name: "Lose Yourself",
      artists: ["Eminem"],
      album_cover: eminem_lose_yourself,
      duration_ms: 320293,
    },
    // pick a taylor song and a kendrick song.. plus one or two old ones
  ];

  const fetchUserStats = useCallback(async () => {
    try {
      setStatsLoading(true);
      const token = localStorage.getItem("strava_token");
      if (!token) {
        navigate("/");
        return;
      }

      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/athlete_stats",
        {
          params: { token },
          withCredentials: true,
        }
      );

      setStats(response.data);
      setStatsLoading(false);

      // Check if there are any YTD activities
      const { ytd_run_totals, ytd_ride_totals, ytd_swim_totals } =
        response.data;
      const hasYtdActivities =
        ytd_run_totals.moving_time > 0 ||
        ytd_ride_totals.moving_time > 0 ||
        ytd_swim_totals.moving_time > 0;

      // Set timeFrame to 'all' if no YTD activities
      if (!hasYtdActivities) {
        setTimeFrame("all");
      }

      // Set default activity based on highest moving time
      const activities = [
        {
          type: "run",
          time: hasYtdActivities
            ? ytd_run_totals.moving_time
            : response.data.all_run_totals.moving_time,
        },
        {
          type: "ride",
          time: hasYtdActivities
            ? ytd_ride_totals.moving_time
            : response.data.all_ride_totals.moving_time,
        },
        {
          type: "swim",
          time: hasYtdActivities
            ? ytd_swim_totals.moving_time
            : response.data.all_swim_totals.moving_time,
        },
      ].filter((a) => a.time > 0);

      if (activities.length > 0) {
        const defaultActivity = activities.reduce((a, b) =>
          a.time > b.time ? a : b
        );
        setSelectedActivity(defaultActivity.type);
      }
    } catch (error) {
      console.error("Error fetching stats:", error);
      setStatsLoading(false);
      navigate("/");
    }
  }, [navigate]);

  const fetchUserProfile = useCallback(async () => {
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        navigate("/");
        return;
      }

      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/user_profile",
        {
          params: { token },
          withCredentials: true,
        }
      );

      const userProfileData = response.data;

      // Check if user is Jason (ID: 13675775)
      if (userProfileData.id !== 13675775) {
        navigate("/");
        return;
      }

      // Store the user profile data
      setUserProfile(userProfileData);

      // Continue with stats fetch after profile verification
      fetchUserStats();
    } catch (error) {
      console.error("Error fetching user profile:", error);
      navigate("/");
    }
  }, [navigate, fetchUserStats]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const getActivityTime = useCallback(() => {
    if (!stats) return 0;
    const totals = {
      run: stats[`${timeFrame === "ytd" ? "ytd" : "all"}_run_totals`]
        .moving_time,
      ride: stats[`${timeFrame === "ytd" ? "ytd" : "all"}_ride_totals`]
        .moving_time,
      swim: stats[`${timeFrame === "ytd" ? "ytd" : "all"}_swim_totals`]
        .moving_time,
    };
    return totals[selectedActivity] || 0;
  }, [stats, timeFrame, selectedActivity]);

  const calculateSongFits = (song) => {
    const activitySeconds = getActivityTime();
    const songSeconds = song.duration_ms / 1000;
    return Math.floor(activitySeconds / songSeconds);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query) {
        if (query.length >= 3) {
          setLoading(true);
          axios
            .get(
              `https://roast-my-strava-backend-production.up.railway.app/search?q=${encodeURIComponent(
                query
              )}`
            )
            .then((response) => {
              console.log("response", response.data);
              setSongs(response.data);
            })
            .catch((error) => {
              console.error("Error searching songs:", error);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setSongs([]);
          setLoading(true);
        }
      } else {
        setSongs([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const handleSelectSong = (content) => {
    console.log("Content selected:", content);
    const formattedContent = {
      id: content.id,
      type: content.type,
      name: content.name,
      artists: content.artists,
      album_cover: content.album_cover,
      duration_ms: content.duration_ms,
      external_urls: content.external_urls,
    };
    console.log("Formatted content:", formattedContent);
    setSelectedSong(formattedContent);
    setQuery("");
    setIsDropdownVisible(false);
    // unfocus the input
    document.activeElement.blur();
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return (
      <TimeDisplay>
        <TimeUnit>
          <TimeValue>{hours}</TimeValue>
          <TimeLabel>hours</TimeLabel>
        </TimeUnit>
        <TimeUnit>
          <TimeValue>{minutes}</TimeValue>
          <TimeLabel>minutes</TimeLabel>
        </TimeUnit>
        <TimeUnit>
          <TimeValue>{remainingSeconds}</TimeValue>
          <TimeLabel>seconds</TimeLabel>
        </TimeUnit>
      </TimeDisplay>
    );
  };

  const availableActivities = stats
    ? ["run", "ride", "swim"].filter(
        (type) =>
          stats[`${timeFrame === "ytd" ? "ytd" : "all"}_${type}_totals`]
            .moving_time > 0
      )
    : [];

  useEffect(() => {
    const targetTime = getActivityTime();
    const duration = 1000;
    const steps = 60;
    const increment = targetTime / steps;
    let current = 0;

    const timer = setInterval(() => {
      current += increment;
      if (current >= targetTime) {
        setAnimatedTime(targetTime);
        clearInterval(timer);
      } else {
        setAnimatedTime(Math.floor(current));
      }
    }, duration / steps);

    return () => clearInterval(timer);
  }, [getActivityTime]);

  useEffect(() => {
    if (selectedSong && window.innerWidth < 1024 && rightColumnRef.current) {
      rightColumnRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedSong]);

  const handleInputBlur = () => {
    // Delay hiding the dropdown to allow click events to register
    const timeout = setTimeout(() => {
      setIsDropdownVisible(false);
    }, 50);
    setCloseTimeout(timeout);
  };

  const handleDropdownItemClick = (song) => {
    // Clear the timeout to prevent dropdown from hiding
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
    handleSelectSong(song);
  };

  return (
    <Container>
      <Helmet>
        <title>Strava Year in Song Calculator | Roast My Strava</title>
      </Helmet>

      <LeftColumn>
        <Title>
          Strava Year in{" "}
          <span style={{ textDecoration: "line-through" }}>Sport</span> Song
        </Title>
        <Subtitle>
          Discover how many times you could loop your favorite track during your
          epic runs, rides, and adventures...
        </Subtitle>

        {statsLoading ? (
          <LoadingContainer>Loading your Strava stats...</LoadingContainer>
        ) : (
          <>
            <TimeToggle>
              <TimeToggleButton
                active={timeFrame === "ytd"}
                onClick={() => setTimeFrame("ytd")}
              >
                {currentYear}
              </TimeToggleButton>
              <TimeToggleButton
                active={timeFrame === "all"}
                onClick={() => setTimeFrame("all")}
              >
                All Time
              </TimeToggleButton>
            </TimeToggle>

            {stats && availableActivities.length > 0 && (
              <ActivityToggle>
                {availableActivities.map((activity) => (
                  <TimeToggleButton
                    key={activity}
                    active={selectedActivity === activity}
                    onClick={() => setSelectedActivity(activity)}
                  >
                    {activity.toUpperCase()}
                  </TimeToggleButton>
                ))}
              </ActivityToggle>
            )}

            {!selectedSong && (
              <StatsDisplay>
                <StatsTitle>
                  {timeFrame === "ytd" ? currentYear : "All-Time"}{" "}
                  {selectedActivity.charAt(0).toUpperCase() +
                    selectedActivity.slice(1)}{" "}
                  Time
                </StatsTitle>
                <StatsTime>{formatTime(animatedTime)}</StatsTime>
              </StatsDisplay>
            )}

            <SearchContainer>
              <InputGroup>
                <Label>Search for a song or artist:</Label>
                <Input
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Sabrina Carpenter"
                  onFocus={() => setIsDropdownVisible(true)}
                  onBlur={handleInputBlur}
                  autoFocus
                />
              </InputGroup>

              {!(isDropdownVisible && query) && (
                <SuggestedSongs>
                  {suggestedSongs.map((song) => (
                    <SuggestedSong
                      key={song.id}
                      onClick={() => {
                        handleSelectSong({
                          ...song,
                          name: song.name,
                          artists: song.artists,
                          album_cover: song.album_cover,
                          duration_ms: song.duration_ms,
                        });
                        setIsDropdownVisible(false);
                        setQuery("");
                      }}
                    >
                      <SuggestedSongImage
                        src={song.album_cover}
                        alt={song.name}
                      />
                      <SuggestedSongInfo>
                        <SuggestedSongName>{song.name}</SuggestedSongName>
                        <SuggestedSongArtist>
                          {song.artists.join(", ")}
                        </SuggestedSongArtist>
                      </SuggestedSongInfo>
                    </SuggestedSong>
                  ))}
                </SuggestedSongs>
              )}

              {isDropdownVisible && query && (
                <SearchDropdown>
                  {loading ? (
                    <DropdownItem>Loading...</DropdownItem>
                  ) : songs.length > 0 ? (
                    songs.map((content) => (
                      <DropdownItem
                        key={content.id}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          handleDropdownItemClick(content);
                        }}
                      >
                        <AlbumCover
                          src={content.album_cover}
                          alt={content.name}
                        />
                        <SongInfo>
                          <SongName>{content.name}</SongName>
                          <ArtistName>{content.artists.join(", ")}</ArtistName>
                        </SongInfo>
                      </DropdownItem>
                    ))
                  ) : (
                    <DropdownItem>No content found</DropdownItem>
                  )}
                </SearchDropdown>
              )}
            </SearchContainer>
            {!selectedSong && (
              <div className="logout-container">
                <img
                  src={poweredByStrava}
                  alt="Powered by Strava"
                  style={{
                    width: "100px",
                    display: "block",
                    margin: "12px auto",
                  }}
                />
                {/* <button onClick={handleLogout} className="logout-button">
                  Logout
                </button> */}
              </div>
            )}
          </>
        )}
      </LeftColumn>

      {selectedSong && (
        <RightColumn ref={rightColumnRef}>
          <ShareCard>
            <ActivityText>
              {userProfile?.firstname ? userProfile.firstname + ", " : ""}
              {timeFrame === "ytd" ? `in ${currentYear} you ` : "all-time you "}
              {selectedActivity === "run"
                ? "ran"
                : selectedActivity === "ride"
                ? "rode"
                : "swam"}
              :
            </ActivityText>
            <ShareCardTimeDisplay>
              <TimeUnit>
                <TimeValue>
                  {formatNumber(Math.floor(getActivityTime() / 3600))}
                </TimeValue>
                <TimeLabel>hours</TimeLabel>
              </TimeUnit>
              <TimeUnit>
                <TimeValue>
                  {formatNumber(Math.floor((getActivityTime() % 3600) / 60))}
                </TimeValue>
                <TimeLabel>minutes</TimeLabel>
              </TimeUnit>
              <TimeUnit>
                <TimeValue>{formatNumber(getActivityTime() % 60)}</TimeValue>
                <TimeLabel>seconds</TimeLabel>
              </TimeUnit>
            </ShareCardTimeDisplay>
            <PlayDetails>
              That's enough time to listen to "{selectedSong.name}" by{" "}
              {selectedSong.artists[0]}{" "}
              <strong>
                {formatNumber(calculateSongFits(selectedSong))} times
              </strong>{" "}
              in a row!
            </PlayDetails>
            <SongHeader>
              <LargeAlbumCover
                src={selectedSong.album_cover}
                alt={selectedSong.name}
              />
              <SongInfo>
                <LargeSongName>{selectedSong.name}</LargeSongName>
                <LargeArtistName>
                  {selectedSong.artists.join(", ")}
                </LargeArtistName>
              </SongInfo>
            </SongHeader>
            <PlayCount>
              {formatNumber(calculateSongFits(selectedSong))} plays back-to-back
              while{" "}
              {selectedActivity === "run"
                ? "running"
                : selectedActivity === "ride"
                ? "riding"
                : "swimming"}{" "}
              {timeFrame === "ytd" ? "in " + currentYear : "all-time"}
            </PlayCount>
            <ShareFooter>
              <span>roastmystrava.com</span>
              <img
                src={poweredByStrava}
                alt="Powered by Strava"
                style={{ height: "40px" }}
              />
            </ShareFooter>
          </ShareCard>
          {/* <div className="logout-container">
            <button onClick={handleLogout} className="logout-button">
              Logout
            </button>
          </div> */}
        </RightColumn>
      )}
    </Container>
  );
}

export default Song;

// notes to keep here!

// sharing!!
// store stats in local storage or db, only fetch like once or twice a day then update when it gets it... log the entire stats and add to user table?
// measure usages? when a song is selected?
